import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/privacy.css";
import { Link } from "react-router-dom";

function Privacy() {
  return (
    <div className="main-container">
      <Header />
      <div className="privacy_mainBox" style={{ marginTop: "124px" }}>
        <div className="first_three_box">
          <div className="first_three_box_grid_about">
            <div className="privacy_about_main_img">
              <div className="overlay"></div>
              <div
                className="block-mv04_txt"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <h1 className="privacy_page-title-hero2">PRIVACY POLICY</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="privacy_black_all_div">
          <p
            className="title_privacy"
            style={{ display: "flex", alignItems: "center", paddingLeft: "5%" }}
          >
            ホーム
            <img
              width="12"
              height="12"
              src="https://img.icons8.com/ios-glyphs/50/FFFFFF/forward.png"
              alt="forward"
            />
            PRIVACY POLICY
          </p>

          <div className="sec_privacy_txt">
            <div className="privacy_sec_inner">
              <p className="first_pp_small">
                以下に当サイトのプライバシーポリシーを記載します。
              </p>
              <h3 className="tit_obi">個人情報の利用目的</h3>
              <p>
                当サイトでは、メールでのお問い合わせなどの際に、名前、メールアドレス等の個人情報をご登録いただく場合がございます。
                <br />
                これらの個人情報は質問に対する回答や必要な情報を電子メールなどをでご連絡する場合に利用させていただくものであり、個人情報をご提供いただく際の目的以外では利用いたしません。
              </p>
              <br />
              <h3 className="tit_obi">個人情報の第三者への開示</h3>
              <p>
                当サイトでは、個人情報は適切に管理し、以下に該当する場合を除いて第三者に開示することはありません。
              </p>
              <ul className="company">
                <li>本人のご了解がある場合</li>
                <li>法令等への協力のため、開示が必要となる場合</li>
              </ul>
              <br />
              <h3 className="tit_obi">
                個人情報の開示、訂正、追加、削除、利用停止
              </h3>
              <p>
                ご本人からの個人データの開示、訂正、追加、削除、利用停止のご希望の場合には、ご本人であることを確認させていただいた上、速やかに対応させていただきます。{" "}
              </p>
              <br />
              <h3 className="tit_obi">クッキー（Cookie）</h3>
              <p>
                当サイトでは、一部のコンテンツについてCookie（クッキー）を利用しています。
                <br />
                Cookieとは、サイトにアクセスした際にブラウザに保存される情報ですが、お名前やメールアドレス等の個人情報は含まれません。
                <br />
                当サイトにアクセスいただいた方々に効果的な広告を配信するためやアクセス解析にCookieの情報を利用させていただく場合があります。
                <br />
                ブラウザの設定により、Cookieを使用しないようにすることも可能です。
                <br />
                当サイトで利用しているアクセス解析は以下のとおりです。
              </p>
              <ul className="company">
                <li>
                  <Link to="https://marketingplatform.google.com/intl/ja/about/analytics/">
                    グーグルアナリティクス
                  </Link>
                </li>
              </ul>

              <br />
              <h3 className="tit_obi">プライバシーポリシーの変更について</h3>
              <p>
                当サイトは、個人情報に関して適用される日本の法令を遵守するとともに、本ポリシーの内容を適宜見直しその改善に努めます。
                <br />
                修正された最新のプライバシーポリシーは常に本ページにて開示されます。
              </p>
              <br />
              <h3 className="tit_obi">免責事項</h3>
              <p>
                当サイトのコンテンツ・情報につきまして、可能な限り正確な情報を掲載するよう努めておりますが、誤情報が入り込んだり、情報が古くなっていることもございます。
                <br />
                当サイトに掲載された内容によって生じた損害等の一切の責任を負いかねますのでご了承ください。
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Privacy;
