const productData = [
  {
    id: 1,
    name: "「シニヨン」ハウツー動画 ",
    details: [
      "※こちらの価格には消費税が含まれています。",
      "※こちらの商品はダウンロード販売です。(807801065 バイト)",
    ],
    description: [
      "ベーシックなスタイルでポイントだけおさえた全体の作り方の動画です。タイトでシンプルなスタイルで時短にもなる基本的な作り方です。着物はもちろんの事ドレスや洋服での「下めアップ」などにも応用できます。",
      "※カーラー巻き、前髪、逆毛、梳き毛などの細かい技術については割愛しております。",
    ],
    images: ["/hair/1001.jpg"],
    price: "3300",
  },
  {
    id: 2,
    name: "「夜会巻き」ハウツー動画",
    details: [
      "※こちらの価格には消費税が含まれています。",
      "※こちらの商品はダウンロード販売です。(425582349 バイト)",
    ],
    description: [
      "ベーシックなスタイルでポイントだけおさえた全体の作り方の動画です。慣れれば時短にもなり着物はもちろんの事ドレスにも似合い幅広く使える「基本」になります。こちらのやり方をベースに大きめのスタイルや流行のスタイルなどに応用できます。",
      "※カーラー巻き、前髪、逆毛、梳き毛など細かい技術については割愛しております。",
    ],
    images: ["/hair/1002.jpg"],
    price: "3300",
  },
  {
    id: 3,
    name: "  ハホニコ　キラメラメ　メンテケアシャンプー",
    details: [
      "※こちらの価格には消費税が含まれています。",
      "※送料が別途¥600かかります。",
    ],
    description: [
      "光成分配合。保湿に優れた処方で内部反射を良くし、ツヤの持続と向上をさせます。",
      "キラメラメ メンテケアシャンプーをアフターで使用すると、表面にキラメラメトリートメントによる多層ネットワーク構造で皮膜ができているので皮膜表面に界面活性剤が吸着し、界面活性剤がミセル状態を作りだします。",
      "ハホニコではこの配合の研究、開発を重ねる事で、最初から泡立ちもよく、そのミセルが毛髪表面でコロコロと転がっていく感じがまるでウナギをつかむようなニュルニュル感、つまりウナギ質感へと変化する処方を作りだしました。",
      "【商品詳細】:",
      "■保湿に優れた処方",
      "アミノ酸系 などの保湿に優れた界面活性剤をバランス良く処方。保湿成分として カチオン化ミルクP.P.T.、乳糖 を配合しました。",
      "■光成分配合",
      "光成分 として 、セラキュートR-G 、光プラチナシルクコロイド を配合しました。",
      "■ツヤの持続と向上",
      "プラチナシルクとセラキュートR-G が内部ダメージホールを埋め内部反射を良くします。機能性ハイブリッドシルクペプチド が表面のツヤ感を向上させます。",
      "■内容量：1000ml",
      "全成分水、ココイルグルタミン酸2Na、コカミドプロピルベタイン、ココイルメチルアラニンNa、コカミドDEA、デシルグルコシド、スルホコハク酸（C12-14）パレス-2Na、グリセリン、カンゾウ根エキス、チャ葉エキス、白金、ポリクオタニウム-92、ヒドロキシプロピルトリモニウム加水分解カゼイン（牛乳）、（加水分解シルク/PG-プロピルメチルシランジオール）クロスポリマー、加水分解シルク、乳糖（牛乳）、ポリクオタニウム-10、トレハロースヒドロキシプロピルトリモニウムクロリド、クエン酸、セルロースガム、炭酸水素Na、BG、フェノキシエタノール、ペンテト酸5Na、香料、メチルパラベン",
    ],
    images: ["/hair/1003.jpg"],
    price: "3550",
  },
];

export default productData;
