import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/about.css";

function About() {
  return (
    <div className="main-container">
      <Header />
      <div className="mainBox">
        <div className="first_three_box">
          <div className="first_three_box_grid_about">
            <div className="about_main_img">
              <div className="overlay"></div>
              <div
                className="block-mv04_txt"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <h1 className="page-title-hero2">ABOUT</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="black_all_div">
          <p>
            ホーム
            <img
              width="12"
              height="12"
              src="https://img.icons8.com/ios-glyphs/50/FFFFFF/forward.png"
              alt="forward"
            />
            ABOUT
          </p>
          <div className="about_first_txt">
            <h2>ABOUT US</h2>
            <div className="first_img">
              <img
                width="800"
                height="600"
                alt=""
                src="https://ginza-roots.com/wp/wp-content/uploads/images/about_img_01.jpg"
              />
            </div>
            <p className="text_cl">
              Hair design Rootsはヘアセットをメインとした美容院です。
              <br />
              創業以来、銀座特有のスタイルをベースにオリジナリティやトレンドを敏感に取り入れた「新しい銀座のスタイル」をモットーに全国に発信しております。
              <br />
              お客様それぞれの魅力を引き出すために小規模、少人数にこだわっておりスピーディーで最高のものを提供する事を心掛けております。
              <br />
              ご来店の際には銀座の伝統や文化の雰囲気をヘアセットと共に体感してもらえたらと思います。
            </p>
            <h2>CONCEPT</h2>
            <div className="first_img">
              <img
                width="800"
                height="600"
                alt=""
                src="https://ginza-roots.com/wp/wp-content/uploads/images/about_img_02.jpg"
              />
            </div>
            <p className="text_cl">
              Hair design Rootsではお客様のご希望に沿うのは勿論のこと、
              <br />
              時には良いアイデアをご提案させて頂きお客様と共にベストなスタイルを創り上げていければと思います。
              <br />
              <br />
              「自分に似合うヘアースタイルが分からない」
              <br />
              「初めてのヘアセットで何も分からない」
              <br />
              「髪の毛が短いけどヘアセットできるの？」
              <br />
              「やりたいイメージは何となくあるけど伝えるのが苦手」
              <br />
              「着物をあまり着ないからどんな和髪が似合うか分からない」
              <br />
              「土日祝の早朝や平日の早い時間帯にもヘアセットしたい」
              <br />
              「プロにお任せしてみたい」
              <br />
              <br />
              など、お客様の不安や疑問にお応えします。
              <br />
              銀座の洗練された技術をベースに時代とともに進化し続ける当店で、
              <br />
              ヘアーを創っていく過程での「ワクワク」や完成した時の「感動」そしてお店を出た後のハッピーを感じてもらえたらと思います。
              <br />
              ヘアセットは特別な時にしかやらないイメージでしたが、これからはもっと気軽にオシャレする一環としてあるものにしたいです。
            </p>
            <h2>OWNER PROFILE</h2>
            <div className="p_img_div">
              <p className="text_cl1">
                東京の美容専門学校を卒業後すぐ銀座の老舗美容院に入社。
                <br />
                そこで文化とも言える銀座のヘアセットに衝撃を受け一般美容師としては勿論の事ヘアセットを中心として学び、美容の道を歩み始める。
                <br />
                銀座でスタイリストになった後、あくまで美容師にこだわりヘアセット専門店ではなく美容院を選択し銀座エリアの数店舗を経て、様々な角度からの知識、技術、人との出会いなど経験し美容学校卒業から約10年後フリーランスとして働き始める。
                <br />
                フリーランスになってからもヘアセットは銀座エリアを中心に恵比寿、代官山エリアでも美容師としての経験をし2014年5月、銀座にHair
                design Rootsをオープンさせる。
                <br />
                <br />
                孤高な存在であり磨かれたスキルと経験や感性からくる唯一無二でオリジナルなヘアセット、これをメインにカット、カラーやヘアセットスクールも店舗で主催しコンテストやshowなどのヘアーを担当したりと、ヘアーデザインルーツを中心に活躍の場を現在も広げ続けている。
              </p>
              <img width="445" height="594" alt="" src="https://ginza-roots.com/wp/wp-content/uploads/images/about_img_03.jpg" />
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default About;
