import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/contact.css";
import { Link } from "react-router-dom";

function Contact() {
  const [formValues, setFormValues] = useState({
    name: "",
    kana: "",
    mailAdd: "",
    chk_mail: "",
    tel1: "",
    tel2: "",
    tel3: "",
    month: "ー",
    day: "ー",
    jiKan: "ー",
    menu: [],
    text: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleCheckboxChange = (e) => {
    const { value } = e.target;
    setFormValues((prev) => ({
      ...prev,
      menu: prev.menu.includes(value)
        ? prev.menu.filter((item) => item !== value)
        : [...prev.menu, value],
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const requiredFields = document.querySelectorAll("th .contact_req");
    let isFormValid = true;

    // 验证必填项 (带有 contact_req 的)
    requiredFields.forEach((req) => {
      const field = req.closest("tr").querySelector("input, select, textarea");

      if (field && !field.value) {
        isFormValid = false;
        field.style.border = "2px solid red"; // 添加红色边框作为错误提示
      } else {
        field.style.border = ""; // 如果填写了，清除错误提示
      }
    });

    // 验证电话部分的三个字段是否为空
    const tel1 = formValues.tel1;
    const tel2 = formValues.tel2;
    const tel3 = formValues.tel3;

    if (!tel1 || !tel2 || !tel3) {
      isFormValid = false;

      document.querySelector("input[name='tel1']").style.border = !tel1
        ? "2px solid red"
        : "";
      document.querySelector("input[name='tel2']").style.border = !tel2
        ? "2px solid red"
        : "";
      document.querySelector("input[name='tel3']").style.border = !tel3
        ? "2px solid red"
        : "";
    }

    if (isFormValid) {
      alert("Form is valid and ready to submit!");
      setFormValues({
        name: "",
        kana: "",
        mailAdd: "",
        chk_mail: "",
        tel1: "",
        tel2: "",
        tel3: "",
        month: "ー",
        day: "ー",
        jiKan: "ー",
        menu: [],
        text: "",
      });
      document.querySelector("input[name='tel2']").style.border = "";
      document.querySelector("input[name='tel3']").style.border = "";
    } else {
      alert("Please fill all required fields.");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  return (
    <div className="main-container">
      <Header />
      <div className="contact_mainBox" style={{ marginTop: "124px" }}>
        <div className="first_three_box">
          <div className="first_three_box_grid_about">
            <div className="contact_about_main_img">
              <div className="overlay"></div>
              <div
                className="block-mv04_txt"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <h1 className="contact_page-title-hero2">お問い合わせ</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="contact_black_all_div">
          <p
            className="title_contact"
            style={{ display: "flex", alignItems: "center", paddingLeft: "5%" }}
          >
            ホーム
            <img
              width="12"
              height="12"
              src="https://img.icons8.com/ios-glyphs/50/FFFFFF/forward.png"
              alt="forward"
            />
            CONTACT
          </p>
          <div className="contact_about_first_txt">
            <div className="contact_two">
              <div className="well2">
                <strong>電話でのお問い合わせはこちら</strong>
                <br />
                <p className="mv_t0_5em">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="-0.5 -0.5 16 16"
                    id="Phone-Flip--Streamline-Font-Awesome"
                    height="16"
                    width="16"
                  >
                    <desc>
                      Phone Flip Streamline Icon: https://streamlinehq.com
                    </desc>{" "}
                    <path
                      d="M10.1689453125 0.720703125c0.2255859375 -0.544921875 0.8203125 -0.8349609375 1.388671875 -0.6796875l2.578125 0.703125C14.6455078125 0.884765625 15 1.34765625 15 1.875c0 7.248046875 -5.876953125 13.125 -13.125 13.125 -0.52734375 0 -0.9902343749999999 -0.3544921875 -1.130859375 -0.8642578125l-0.703125 -2.578125c-0.1552734375 -0.568359375 0.134765625 -1.1630859375 0.6796875 -1.388671875l2.8125 -1.171875c0.4775390625 -0.19921875 1.03125 -0.0615234375 1.3564453125 0.33984375l1.18359375 1.4443359375c2.0625 -0.9755859374999999 3.732421875 -2.6455078125 4.7080078125 -4.7080078125L9.3369140625 4.892578125c-0.4013671875 -0.328125 -0.5390625 -0.87890625 -0.33984375 -1.3564453125l1.171875 -2.8125z"
                      stroke-width="1"
                    ></path>
                  </svg>{" "}
                  <strong className="point14">
                    <a href="tel:0362806606" className="black point16">
                      03-6280-6606
                    </a>
                  </strong>
                </p>
              </div>
              <div className="well2">
                <strong>メールでのお問い合わせはこちら</strong>
                <p className="mv_t0_5em">
                  <strong className="point14">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      height="16"
                      width="16"
                    >
                      {" "}
                      <path d="M64 112c-8.8 0-16 7.2-16 16l0 22.1L220.5 291.7c20.7 17 50.4 17 71.1 0L464 150.1l0-22.1c0-8.8-7.2-16-16-16L64 112zM48 212.2L48 384c0 8.8 7.2 16 16 16l384 0c8.8 0 16-7.2 16-16l0-171.8L322 328.8c-38.4 31.5-93.7 31.5-132 0L48 212.2zM0 128C0 92.7 28.7 64 64 64l384 0c35.3 0 64 28.7 64 64l0 256c0 35.3-28.7 64-64 64L64 448c-35.3 0-64-28.7-64-64L0 128z" />
                    </svg>{" "}
                    answer@cepphial.quest
                  </strong>
                </p>
              </div>
            </div>
          </div>

          <div className="sec_contact_txt">
            <div className="sec_inner">
              <h3 className="tit_obi">
                メールフォームでのお問い合わせはこちら
              </h3>
              <p>
                以下のフォームに必要事項をご入力の上、「確認」ボタンをクリックしてください。
              </p>
              <ul className="company">
                <li>
                  24時間以内の回答を心がけておりますが、相談内容等によって、2〜3日かかる事もございます。御了承下さい。
                </li>
                <li>
                  携帯電話でのメールアドレスを入力される場合は、携帯電話でのドメイン拒否を解除して下さい。
                </li>
                <li>
                  入力頂いたメールアドレスにて返信ができない場合は、入力されたお電話にてお答えをさせて頂く場合があります。
                </li>
                <li>
                  個人情報の取り扱いについては、当社の「
                  <Link to="/privacy" style={{ color: "#8fb9ad" }}>
                    プライバシーポリシー
                  </Link>
                  」ページをご確認ください。
                </li>
              </ul>

              <form encType="multipart/form-data">
                <table className="contact_table">
                  <tbody>
                    <tr>
                      <th>
                        <span className="contact_req">必須</span> お名前
                      </th>
                      <td>
                        <input
                          type="text"
                          name="name"
                          className="sp_100"
                          size="30"
                          maxLength="30"
                          value={formValues.name}
                          onChange={handleInputChange}
                        />
                      </td>
                    </tr>

                    <tr>
                      <th>
                        <span className="contact_req">必須</span> フリガナ
                      </th>
                      <td>
                        <input
                          type="text"
                          name="kana"
                          className="sp_100"
                          size="30"
                          value={formValues.kana}
                          onChange={handleInputChange}
                        />
                      </td>
                    </tr>

                    <tr>
                      <th>
                        <span className="contact_req">必須</span> メールアドレス
                      </th>
                      <td>
                        <input
                          type="email"
                          name="mailAdd"
                          className="sp_100"
                          size="30"
                          maxLength="50"
                          value={formValues.mailAdd}
                          onChange={handleInputChange}
                        />
                      </td>
                    </tr>

                    <tr>
                      <th>
                        <span className="contact_req">必須</span>{" "}
                        メールアドレス（再入力）
                      </th>
                      <td>
                        <input
                          type="email"
                          name="chk_mail"
                          className="sp_100"
                          size="30"
                          maxLength="50"
                          value={formValues.chk_mail}
                          onChange={handleInputChange}
                        />
                      </td>
                    </tr>

                    <tr>
                      <th>
                        <span className="contact_req">必須</span>お電話番号
                      </th>
                      <td>
                        <span className="mwform-tel-field">
                          <input
                            type="text"
                            name="tel1"
                            className="form_tel"
                            size="6"
                            maxLength="5"
                            value={formValues.tel1}
                            onChange={handleChange}
                          />
                          -
                          <input
                            type="text"
                            name="tel2"
                            className="form_tel"
                            size="5"
                            maxLength="4"
                            value={formValues.tel2}
                            onChange={handleChange}
                          />
                          -
                          <input
                            type="text"
                            name="tel3"
                            className="form_tel"
                            size="5"
                            maxLength="4"
                            value={formValues.tel3}
                            onChange={handleChange}
                          />
                        </span>
                        <span className="point10">※携帯電話も可</span>
                      </td>
                    </tr>

                    <tr>
                      <th>
                        <span className="contact_unreq">任意</span> ご予約希望
                      </th>
                      <td>
                        <select
                          name="month"
                          value={formValues.month}
                          onChange={handleInputChange}
                        >
                          <option value="ー">ー</option>
                          {[...Array(12)].map((_, i) => (
                            <option key={i + 1} value={i + 1}>
                              {i + 1}
                            </option>
                          ))}
                        </select>
                        月
                        <select
                          name="day"
                          value={formValues.day}
                          onChange={handleInputChange}
                        >
                          <option value="ー">ー</option>
                          {[...Array(31)].map((_, i) => (
                            <option key={i + 1} value={i + 1}>
                              {i + 1}
                            </option>
                          ))}
                        </select>
                        日 / 希望時間
                        <select
                          name="jiKan"
                          value={formValues.jiKan}
                          onChange={handleInputChange}
                        >
                          <option value="ー">ー</option>
                          {[
                            "10:00〜",
                            "11:00〜",
                            "12:00〜",
                            "13:00〜",
                            "14:00〜",
                            "15:00〜",
                            "16:00〜",
                            "17:00〜",
                            "18:00〜",
                            "19:00〜",
                            "20:00〜",
                            "21:00〜",
                            "22:00〜",
                          ].map((time) => (
                            <option key={time} value={time}>
                              {time}
                            </option>
                          ))}
                        </select>
                      </td>
                    </tr>

                    <tr>
                      <th>
                        <span className="contact_unreq">任意</span>{" "}
                        ご希望のメニュー
                      </th>
                      <td style={{ display: "grid", rowGap: "16px" }}>
                        {[
                          "カット",
                          "パーマ",
                          "トリートメント",
                          "カラーリング",
                          "メイクアップ",
                          "アップ／セット",
                        ].map((menuItem) => (
                          <label key={menuItem}>
                            <input
                              type="checkbox"
                              name="menu"
                              value={menuItem}
                              checked={formValues.menu.includes(menuItem)}
                              onChange={handleCheckboxChange}
                            />
                            {menuItem}
                          </label>
                        ))}
                      </td>
                    </tr>

                    <tr>
                      <th>
                        <span className="contact_unreq">任意</span> お問合せ内容
                      </th>
                      <td>
                        <textarea
                          name="text"
                          cols="50"
                          rows="10"
                          value={formValues.text}
                          onChange={handleInputChange}
                          style={{ width: "100%" }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div className="text_c eds-on-scroll eds-scroll-hidden">
                  <input
                    type="submit"
                    name="submitConfirm"
                    value="確認"
                    className="confbtn"
                    onClick={handleSubmit}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Contact;
