import React, { useState, useContext } from "react";
import { useParams } from "react-router-dom";
import productData from "../Datas/productData";
import Header from "./Header";
import Footer from "./Footer";
import "../css/productdetails.css";
import { useNavigate } from "react-router-dom";
import { CartContext } from "../Component/CartContext";

const ProductDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const product = productData.find((item) => item.id === parseInt(id));
  const { toCheck } = useContext(CartContext);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [quantities, setQuantities] = useState(1);

  const handleAddToCart = () => {
    toCheck(product.id, quantities);

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    navigate("/checkout");
  };

  if (!product) {
    return <div>商品が見つかりません</div>;
  }

  return (
    <>
      {" "}
      <Header />
      <div className="main-container">
        <div className="shopDiv">
          <div id="pagecontacttitle" className="normal">
            <h1 class="mainfc noto_t">
              ハホニコ　キラメラメ　メンテケアシャンプー
            </h1>
          </div>
          <div className="product-detail">
            <div className="product-images">
              <div className="carousel">
                {product.images.map((image, index) => (
                  <img
                    key={index}
                    src={image}
                    alt={`${product.name}`}
                    style={{
                      display: index === currentIndex ? "block" : "none",
                    }}
                  />
                ))}
              </div>
              <div className="carousel-buttons">
                {product.images.map((image, index) => (
                  <button key={index} onClick={() => setCurrentIndex(index)}>
                    <img src={image} alt={`${product.name}`} />
                  </button>
                ))}
              </div>
            </div>
            <div className="product-info">
              <h3 className="sldo__name">
                <strong style={{ color: "#000" }}>¥{product.price}</strong>
              </h3>
              {product.details.map((detail, index) => (
                <p key={index}>{detail}</p>
              ))}
              <span style={{ fontWeight: "700" }}>
                数量：&nbsp;
                <select
                  value={quantities}
                  onChange={(e) => setQuantities(parseInt(e.target.value))}
                  className="quantity-select"
                >
                  {Array.from({ length: 99 }, (_, i) => i + 1).map((num) => (
                    <option key={num} value={num}>
                      {num}
                    </option>
                  ))}
                </select>
              </span>
              <br />
              <div className="btnDiv" onClick={handleAddToCart}>
                <button className="add-to-cart">カートに入れる</button>
              </div>
              <div className="des_p">
                {product.description.map((des, index) => (
                  <p key={index}>{des}</p>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ProductDetail;
