import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/main.css";
import { Link } from "react-router-dom";
import productData from "../Datas/productData";

function Main() {
  const handleNavigate = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className="main-container">
      <Header />
      <div className="left_absolute_img">
        <img
          alt=""
          src="https://ginza-roots.com/wp/wp-content/uploads/images/side_logo.png"
        />
      </div>
      <div className="mainBox">
        <div className="transparent-div">
          <img alt="" src="/head/main.jpg" style={{ width: "100%" }} />
          <div
            className="block-mv04_txt"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <img
              width="600"
              height="344"
              alt=""
              src="/head/head.svg"
              style={{ width: "100%" }}
            />
          </div>
        </div>

        <div className="top_news_area">
          <table>
            <tbody>
              <tr>
                <th>
                  <Link to="/">NEWS</Link>
                </th>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="top_about">
          <div className="about_container">
            <img width="540" height="405" alt="" src="/main/1001.jpg" />
            <div className="about_msg">
              <h2>ABOUT US</h2>
              <p>
                Hair design Rootsはヘアセットをメインとした美容院です。
                <br />
                創業以来、銀座特有のスタイルをベースにオリジナリティやトレンドを敏感に取り入れた「新しい銀座のスタイル」をモットーに全国に発信しております。
                <br />
                お客様それぞれの魅力を引き出すために小規模、少人数にこだわっておりスピーディーで最高のものを提供する事を心掛けております。
                <br />
                ご来店の際には銀座の伝統や文化の雰囲気をヘアセットと共に体感していただけたらと思っております。
              </p>
            </div>
          </div>
        </div>

        <div className="top_menu">
          <h2>MENU</h2>
          <div className="menu_grid">
            {productData.map((menu, index) => (
              <div className="menu_inner" key={index}>
                <img width="278" height="278" alt="" src={menu.images[0]} />
                <h3>{menu.name}</h3>
                <p>
                  <strong>¥{menu.price}</strong>
                </p>
                <Link to={`/shopDetails/${menu.id}`} onClick={handleNavigate}>
                  READ MORE
                </Link>
              </div>
            ))}
          </div>
        </div>

        <div className="top_about">
          <div className="about_container">
            <div className="about_msg">
              <h2>ONLINE SCHOOL</h2>
              <p>
                当店では美容師、ヘアメイク、美容学生、着付師などに向けたヘアセットスクールをオンラインや対面でも行なっております。
                <br />
                和装ヘアーから洋装ヘアーまで受講生のやりたいスタイルや当店オリジナルの基礎講習などもあり1回ごとのコースとカリキュラムを組んだ複数回コースで、それぞれに合わせて内容や回数は決めることもできます。
              </p>
            </div>
            <img width="540" height="405" alt="" src="/main/1002.jpg" />
          </div>
        </div>

        <div className="top_menu1">
          <h2>STAFF BLOG</h2>
          <div className="menu_grid1">
            <div className="menu_inner">
              <h3>臨時休業日について</h3>
              <p>
                <img
                  width="16"
                  height="18"
                  src="https://img.icons8.com/ios-glyphs/30/calendar.png"
                  alt="calendar"
                />
                2024年9月20日
              </p>
            </div>
            <div className="menu_inner">
              <h3>夏季休暇について</h3>
              <p>
                <img
                  width="16"
                  height="18"
                  src="https://img.icons8.com/ios-glyphs/30/calendar.png"
                  alt="calendar"
                />
                2024年8月6日
              </p>
            </div>
            <div className="menu_inner">
              <h3>2024/8月5日(月)、6日(火)の早朝ご予約について</h3>
              <p>
                <img
                  width="16"
                  height="18"
                  src="https://img.icons8.com/ios-glyphs/30/calendar.png"
                  alt="calendar"
                />
                2024年7月8日
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Main;
